"use client";

import { useEffect, useState } from "react";
import { find, isEmpty, map, max } from "lodash";
import { useSession } from "next-auth/react";
import { useTranslations } from "next-intl";

import Radio from "~/components/Radio";
import { Alert, Spinner } from "~/components";
import { appTRPC } from "~/providers/TRPCProvider";
import {
  PlanPeriod,
  type PlanSummary,
  type SupportedCountryCode,
} from "~/types";
import { PricingPlanCardWidget } from "~/widgets";

interface Props {
  country: SupportedCountryCode;
  subscriptionSummary: PlanSummary["summary"];
  onShowPlansClick: () => void;
}

function Paywall({
  country,
  subscriptionSummary,
  onShowPlansClick,
}: Readonly<Props>) {
  useSession({ required: true });

  const t = useTranslations("errors.blockedSubscriptionError.paywall");

  const [selectedPlanPeriod, setSelectedPlanPeriod] = useState<number>(
    PlanPeriod.MONTHLY,
  );

  const { data: planOptions, isPending: loadingPlans } =
    appTRPC.planSubscriptions.getPlanOptions.useQuery();

  useEffect(() => {
    const maxPeriodPlanPrice = max(planOptions?.periods);

    if (maxPeriodPlanPrice) {
      setSelectedPlanPeriod(maxPeriodPlanPrice);
    }
  }, [planOptions?.periods]);

  const advancedOption = find(
    planOptions?.optionsByPeriod[selectedPlanPeriod],
    ({ plan }) => plan.category === "advanced",
  );

  if (loadingPlans) {
    return (
      <div className="flex w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!planOptions || !advancedOption || !subscriptionSummary) {
    return <Alert appearance="danger">{t("fetchError")}</Alert>;
  }

  const { periods, savingsPercentageByPeriod, basePriceAmountByCategory } =
    planOptions;

  return (
    <main className="flex flex-col items-center justify-center pt-8">
      <div className="flex w-full max-w-[944px] flex-col items-center gap-6">
        <div className="flex flex-col items-center gap-4">
          <h1 className="text-center text-[32px] font-medium leading-[44px] text-secondary-pure">
            {t("title")}
          </h1>
          <p className="text-lg leading-6 text-neutral-text-disabled">
            {t(`description.${country}`)}
          </p>
        </div>
        {!isEmpty(periods) && (
          <div className="flex justify-center gap-4">
            {map(periods, (periodInDays) => {
              const planPeriodName = t("planPeriodName", {
                period: periodInDays,
              });

              const savings = savingsPercentageByPeriod[periodInDays];
              const savingsText = savings ? ` -${savings.toFixed(0)}%` : "";

              return (
                <Radio
                  as="button"
                  key={periodInDays}
                  name={planPeriodName}
                  label={`${planPeriodName}${savingsText}`}
                  checked={selectedPlanPeriod == periodInDays}
                  onChange={() => setSelectedPlanPeriod(periodInDays)}
                />
              );
            })}
          </div>
        )}
        <div className="w-[400px]">
          <PricingPlanCardWidget
            isFeaturedPlan
            plan={advancedOption.plan}
            basePriceAmount={basePriceAmountByCategory["advanced"]}
            planPrice={advancedOption.price}
            summary={subscriptionSummary}
            onShowPlansClick={onShowPlansClick}
          />
        </div>
      </div>
    </main>
  );
}

export default Paywall;
