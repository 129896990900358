"use client";

import { useEffect } from "react";
import { CogIcon } from "@nimbus-ds/icons";
import { useTranslations } from "next-intl";

import logger from "@offline/logger/logger";

import { FullscreenErrorMessage } from "~/components";
import { Link } from "~/navigation";
import { type StoreInfo } from "~/types";

interface Props {
  storeInfo: StoreInfo;
}

export default function NoLocationsFound({ storeInfo }: Readonly<Props>) {
  const t = useTranslations();

  const { originalDomain } = storeInfo;

  useEffect(() => {
    logger.info(storeInfo, "No locations found");
  }, [storeInfo]);

  return (
    <FullscreenErrorMessage
      title={t("layout.noLocationsFound.title")}
      description={t("layout.noLocationsFound.description")}
    >
      <Link
        href={`https://${originalDomain}/admin/v2/settings/locations`}
        target="_blank"
        className="flex items-center justify-center gap-1 text-base/5 text-primary-interactive underline"
      >
        <CogIcon />
        {t("layout.noLocationsFound.adminLocationsLink")}
      </Link>
    </FullscreenErrorMessage>
  );
}
