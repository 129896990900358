import { usePathname } from "next/navigation";
import { useHotkeys as useHotkeysHook, type Options } from "react-hotkeys-hook";

import { useSubscriptionSummary } from "~/providers/SubscriptionSummaryProvider";
import { useCheckoutStore } from "./store";

const HOTKEY_USAGE_SECTION_MAP: Record<string, string> = {
  "/cart": "Cart",
  "/checkout": "Checkout",
};

const useHotkeys = (key: string, callback: () => void, options?: Options) => {
  const { features } = useSubscriptionSummary();
  const { saveUsedShortcut } = useCheckoutStore();
  const pathname = usePathname();

  const hotkeyUsageSection = HOTKEY_USAGE_SECTION_MAP[pathname] || pathname;
  const isWhitelistedRoute = ["/cart", "/checkout"].includes(pathname);
  const shouldEnableHotkeys = features.keyboardShortcuts && isWhitelistedRoute;

  const handleHotkey = () => {
    callback();
    saveUsedShortcut(key.toUpperCase(), hotkeyUsageSection);
  };

  useHotkeysHook(key, handleHotkey, {
    ...options,
    enabled: shouldEnableHotkeys && options?.enabled,
  });
};

export default useHotkeys;
