import { useCallback, useState } from "react";
import { ChevronLeftIcon, InfoCircleIcon } from "@nimbus-ds/icons";
import { size } from "lodash";
import { useTranslations } from "next-intl";

import { Button, FullscreenErrorMessage, IconButton } from "~/components";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import getBillingCheckoutCallbackUrl from "~/helpers/getBillingCheckoutCallbackUrl";
import goToBillingPaymentLinkUrl from "~/helpers/goToBillingPaymentLinkUrl";
import { useRouter } from "~/navigation";
import { appTRPC } from "~/providers/TRPCProvider";
import { type PlanSummary, type SupportedCountryCode } from "~/types";
import { AvailablePlansWidget } from "~/widgets";
import Paywall from "./Paywall";

export default function BlockedSubscriptionError({
  planPricesIds,
  isFreeTrial,
  country,
  summary,
}: Readonly<{
  planPricesIds: string[];
  isFreeTrial: boolean;
  country: SupportedCountryCode;
  summary: PlanSummary["summary"];
}>) {
  const t = useTranslations("errors.blockedSubscriptionError");
  const [showPlans, setShowPlans] = useState(false);

  const router = useRouter();

  const { mutateAsync: generatePaymentLink } =
    appTRPC.planSubscriptions.payNextPeriod.useMutation();

  const handlePaySubscription = useCallback(async () => {
    if (size(planPricesIds) > 1) {
      router.push("/account/payment-period");
      return;
    }

    const planPriceId = planPricesIds[0];

    if (!planPriceId) {
      return;
    }

    const { paymentLink } = await generatePaymentLink({
      planPriceId,
      callbackUrl: getBillingCheckoutCallbackUrl(),
    });

    goToBillingPaymentLinkUrl(paymentLink, t("popUpBlockedMessage"));
  }, [generatePaymentLink, planPricesIds, router, t]);

  const handleShowPlansClick = (
    from: "unpaid_subscription" | "trial_paywall",
  ) => {
    trackAmplitudeEvent("pdv_all_see_plans_click", { from });
    setShowPlans(true);
  };

  if (showPlans) {
    return (
      <main className="flex flex-col items-center">
        <div className="flex w-full max-w-[944px] flex-col gap-6 py-6">
          <div className="flex gap-3 self-start pb-4">
            <IconButton onClick={() => setShowPlans(false)}>
              <ChevronLeftIcon size="medium" />
            </IconButton>

            <h1 className="self-center text-3xl font-bold text-secondary-pure">
              {t("plans.title")}
            </h1>
          </div>

          <div className="flex flex-col gap-2">
            <h2 className="text-center text-2xl font-semibold leading-7 text-neutral-text-high">
              {t("plans.subtitle")}
            </h2>
            <p className="text-center text-lg leading-6 text-neutral-text-low">
              {t(`plans.description.${country}`)}
            </p>
          </div>

          <AvailablePlansWidget subscriptionSummary={summary} />
        </div>
      </main>
    );
  }

  if (isFreeTrial) {
    return (
      <Paywall
        country={country}
        subscriptionSummary={summary}
        onShowPlansClick={() => handleShowPlansClick("trial_paywall")}
      />
    );
  }

  return (
    <FullscreenErrorMessage
      icon={<InfoCircleIcon size="large" />}
      title={t("title")}
      description={t("description")}
    >
      <div className="flex items-center justify-center gap-6">
        <button
          className="text-primary-interactive underline hover:text-primary-interactive-hover"
          onClick={() => handleShowPlansClick("unpaid_subscription")}
          onKeyDown={() => handleShowPlansClick("unpaid_subscription")}
          title={t("seePlansLink")}
        >
          {t("seePlansLink")}
        </button>
        <Button onClick={handlePaySubscription}>{t("payButton")}</Button>
      </div>
    </FullscreenErrorMessage>
  );
}
