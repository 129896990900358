import { useStoreInfo } from "~/providers/StoreInfoProvider";

const useAdminUrls = () => {
  const { originalDomain } = useStoreInfo();
  const baseUrl = `https://${originalDomain}/admin`;

  return {
    accountPlans: `${baseUrl}/v2/account/plans`,
    accountCheckoutCharges: `${baseUrl}/v2/account/checkout/charges`,
  };
};

export default useAdminUrls;
