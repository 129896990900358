import FullscreenModal from "~/components/FullscreenModal";

interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  videoId: string;
}

const SubscriptionVideoModal = ({
  isOpen,
  onClose,
  videoId,
}: Readonly<VideoModalProps>) => {
  return (
    <FullscreenModal open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="mx-auto w-full sm:max-w-screen-xl">
          <div className="relative aspect-video p-4">
            <iframe
              className="left-0 top-0 h-full w-full rounded-lg"
              src={`https://www.youtube.com/embed/${videoId}`}
              title="Tiendanube subscription video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </FullscreenModal>
  );
};

export default SubscriptionVideoModal;
